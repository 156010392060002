import React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"
import Alexandra from "../../components/pages/alexandra/alexandra"
import AccessWrapper from '../../components/pages/AccessWrapper'

// console.log(list)

const AlexandraPage = ({ data }) => {

  const story = data.storyblokEntry
  const content = JSON.parse(story.content)
  // console.log("content", content)
  // const media = content.media
  const media = content.alexandra_video

  return(
    <Layout>
      <SEO title="Home" />
      <AccessWrapper introString='Alexandra Leykauf' content={content.artists[4]} id={4}>
        <Alexandra media={media} content={content}/>
      </AccessWrapper>
    </Layout>
  )
}

export default AlexandraPage

export const query = graphql`
  query {
    storyblokEntry(slug: { eq: "artists" }) {
      content
    }
  }
`