import React, { useEffect, useRef, useState } from "react"
import VideoPlayer from "../../elements/videoPlayer"
import style from "./alexandra.module.scss"


const Alexandra = ({ media, content }) => {


    const [ state, setState ] = useState({
        top: 0,
        left: 0,
    })

    const handleMousemove = (e) => {
        const container = e.currentTarget

        const left = e.pageX
        const top = e.pageY
        setState({
            top,
            left
        })
    }

    const videoContainer = useRef()

    return(
        <div className={style.alexandra} onMouseMove={handleMousemove} ref={videoContainer}>
            <VideoPlayer
            video={media}
            isMuted={true}
            padding={0}
            className={style.video}
            />
        </div>
    )
}

export default Alexandra
